var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed inset-0 overflow-hidden add-live-account-vue"},[_c('div',{staticClass:"absolute inset-0 overflow-hidden"},[_vm._m(0),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, reset }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addAccount)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('section',{staticClass:"absolute inset-y-0 right-0 pl-0 sm:pl-10 max-w-full flex"},[_c('div',{staticClass:"w-screen max-w-none sm:max-w-lg"},[_c('div',{staticClass:"h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"},[_c('div',{staticClass:"min-h-0 flex-1 flex flex-col pb-6 overflow-y-scroll"},[_c('header',{staticClass:"px-8 py-6 bg-gray-100"},[_c('div',[_c('div',{staticClass:"flex justify-between"},[_c('h3',{staticClass:"text-lg leading-6 font-normal text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t("add-a-new-trading-account"))+" ")])]),(
                        (_vm.whitelabel === 'TDSouthAfrica' ||
                          _vm.whitelabel === 'TD365') &&
                        _vm.$store.getters.get_live_backend_accounts.length === 0
                      )?_c('p',{staticClass:"mt-1 max-w-2xl text-sm font-light text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t("sorry-you-cannot-create-more-trading-accounts"))+" ")]):(
                        (_vm.$store.getters.get_live_backend_accounts.length ===
                          0 ||
                          _vm.$store.getters.get_live_accounts.length >= 1) &&
                        _vm.whitelabel === 'GCCBrokers'
                      )?_c('p',{staticClass:"mt-1 max-w-2xl text-sm font-light text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t("sorry-you-cannot-create-more-trading-accounts"))+" ")]):_c('p',{staticClass:"mt-1 max-w-2xl text-sm font-light text-gray-500"},[(_vm.$store.getters.get_profile.state === 'Full')?_c('span',[_vm._v(" "+_vm._s(_vm.$t( "choose-your-desired-trading-platform-and-currency-settings" ))+" ")]):_vm._e()]),_c('div',{staticClass:"h-7 flex items-center absolute top-5 right-5",on:{"click":function($event){return _vm.$emit('close')}}},[_c('button',{staticClass:"text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none",attrs:{"aria-label":"Close panel"}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 18L18 6M6 6l12 12","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2"}})])])])])]),(
                    _vm.$store.getters.get_profile.profile.hasOwnProperty(
                      'limits'
                    )
                  )?_c('div',[(
                      _vm.$store.getters.get_profile.profile.limits !== null &&
                      _vm.checkNumberOfAccounts(_vm.$store.getters.get_profile) >=
                        _vm.$store.getters.get_profile.profile.limits.accounts
                    )?_c('div',[_c('p',{staticClass:"px-6 py-6"},[_vm._v(" Sorry, you cannot create more trading accounts untill you upgrade your account ")]),_vm._v(" "+_vm._s(_vm.showTheContent(false))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.showTheContent(true))+" ")])]):_vm._e(),(_vm.showContent)?_c('div',{staticClass:"relative flex-1 mt-0 sm:p-0 p-6"},[_c('div',[(
                        _vm.$store.getters.get_profile.state !== 'Full' &&
                        _vm.$store.getters.get_profile.state !== 'Limited'
                      )?_c('complete-id-identification',{attrs:{"title":""}}):(
                        !(
                          _vm.$store.getters.get_live_accounts.length >= 1 &&
                          _vm.whitelabel === 'GCCBrokers'
                        )
                      )?_c('div',{staticClass:"sm:px-0 pt-8"},[(
                          _vm.$store.getters.get_live_backend_accounts.length > 0
                        )?_c('dl',[_c('div',{staticClass:"sm:px-8 sm:py-2"},[_c('dt',{staticClass:"mb-1 text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("select-account"))+" ")]),_c('dd',{staticClass:"text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2 flex"},_vm._l((_vm.get_live_backend_accounts),function(account,index){return _c('button',{key:index,staticClass:"cursor-pointer p-5 border rounded mr-3 w-full focus:outline-none",class:_vm.selectedAccount === account
                                    ? 'border-blue-500'
                                    : '',attrs:{"id":"account_1","type":"reset"},on:{"click":function($event){return _vm.setAccount(account)}}},[(
                                    account.backend.name ===
                                    'Cloud-SingleCurrencyTrading'
                                  )?_c('img',{staticClass:"m-auto",attrs:{"src":_vm.selectedAccount === account
                                      ? require(`../../assets/icon-${account.backend.name}-white.png`)
                                      : require(`../../assets/icon-${account.backend.name}.png`),"alt":"Meta Logo"}}):_c('img',{staticClass:"m-auto",attrs:{"src":_vm.selectedAccount === account
                                      ? require(`../../assets/${account.backend.type}-white.png`)
                                      : require(`../../assets/${account.backend.type}.png`),"alt":"Meta Logo"}})])}),0)])]),(_vm.selectedAccount.backend)?_c('div',[(_vm.whitelabel.includes('FX'))?_c('div',{staticClass:"sm:px-8 mt-5"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t("Password"))+" "),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(" * ")])]),_c('ValidationProvider',{attrs:{"name":"Password","rules":{
                                required: true,
                                regex: _vm.passwordPattern,
                              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"mt-1 rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"password","name":"password","type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),(errors[0])?_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(" "+_vm._s(_vm.$t( "password-must-be-8-to-15-characters-long-contain-four-character-types-lowercase-letters-uppercase-letters-numbers-and-symbols" ))+"}")]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"sm:px-8 mt-5"},[_c('dt',{staticClass:"mb-1 text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("Currency"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[(_vm.selectedAccount.backend)?_c('ValidationProvider',{attrs:{"name":"password_current","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"options":_vm.get_live_backend_accounts.find(
                                        (x) =>
                                          x.backend ===
                                          _vm.selectedAccount.backend
                                      ).currencies,"placeholder":_vm.$t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                      ),"label":"symbol"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)])])]):_vm._e(),(
                            _vm.whitelabel === 'ICMEU' ||
                            _vm.whitelabel === 'ICMTrader' ||
                            _vm.whitelabel === 'ICMMENA' ||
                            _vm.whitelabel.includes('FX')
                          )?_c('div',{staticClass:"sm:px-8 mt-5 mb-1"},[_c('dt',{staticClass:"text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("Leverage"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[_c('ValidationProvider',{attrs:{"name":"leverage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"options":_vm.leverages,"placeholder":_vm.$t(
                                      'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                    ),"label":"name"},model:{value:(_vm.leverage),callback:function ($$v) {_vm.leverage=$$v},expression:"leverage"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e(),(
                            _vm.whitelabel === 'OneTradingMarkets' &&
                            _vm.selectedAccount.backend
                          )?[(_vm.selectedAccount.backend.name === 'MT5')?_c('div',{staticClass:"sm:px-8 mt-5 mb-1"},[_c('dt',{staticClass:"mb-1 text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("account-type"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[_c('ValidationProvider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"options":_vm.accountTypesOTM,"placeholder":_vm.$t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                      ),"label":"symbol"},model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e()]:_vm._e(),(
                            _vm.whitelabel !== 'TD365' &&
                            _vm.whitelabel !== 'TDSouthAfrica' &&
                            _vm.whitelabel !== 'OneTradingMarkets' &&
                            _vm.whitelabel !== 'EagleGlobalMarkets' &&
                            _vm.whitelabel !== 'TradeCoreUK' &&
                            _vm.whitelabel !== 'VCGMarkets' &&
                            _vm.whitelabel !== 'Dzengi'
                          )?_c('div',{staticClass:"sm:px-8 mt-5 mb-1"},[_c('dt',{staticClass:"mb-1 text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("account-type"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[_c('ValidationProvider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"options":_vm.accountTypes,"placeholder":_vm.$t(
                                      'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                    ),"reduce":(item) =>
                                      _vm.whitelabel === 'PolarisMarkets'
                                        ? item.value
                                        : item,"label":_vm.whitelabel === 'PolarisMarkets'
                                      ? 'label'
                                      : 'symbol'},model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e(),(_vm.selectedAccount.backend)?_c('div',[(
                              (_vm.whitelabel === 'ICMVC' ||
                                _vm.whitelabel === 'ICMAP' ||
                                _vm.whitelabel === 'ICMMU' ||
                                _vm.whitelabel === 'ICMMENA') &&
                              (_vm.selectedAccount.backend.type === 'MT5' ||
                                _vm.selectedAccount.backend.type === 'MT4')
                            )?_c('div',{staticClass:"sm:px-8 mt-5 mb-1"},[_c('dt',{staticClass:"mb-1 text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("agent-number"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[_c('ValidationProvider',{attrs:{"name":"agent-number","rules":"max_value_agent:10"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agentNumber),expression:"agentNumber"}],staticClass:"form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"placeholder":_vm.$t('agent-number'),"min":"0","oninput":"validity.valid||(value='');","type":"number"},domProps:{"value":(_vm.agentNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.agentNumber=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e()]):_vm._e()],2):_vm._e()]):_vm._e()],1)]):_vm._e()]),(_vm.showContent)?_c('div',[(_vm.$store.getters.get_live_backend_accounts.length > 0)?_c('div',{staticClass:"flex-shrink-0 px-8 py-4 space-x-4 flex justify-end"},[_c('span',{staticClass:"inline-flex rounded-md shadow-sm ml-3"},[_c('button',{staticClass:"relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50",attrs:{"type":"reset"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])]),(
                      _vm.$store.getters.get_profile.state === 'Full' ||
                      _vm.$store.getters.get_profile.state === 'Limited'
                    )?_c('span',{staticClass:"inline-flex rounded-md shadow-sm"},[_c('Button',{staticClass:"relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo",attrs:{"disabled":_vm.isDisabled ||
                        (_vm.$store.getters.get_live_accounts.length >= 1 &&
                          _vm.whitelabel === 'GCCBrokers'),"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("create-account"))+" ")])],1):_vm._e()]):_vm._e()]):_vm._e()])])])])]}}])})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed inset-0 transition-opacity"},[_c('div',{staticClass:"absolute inset-0 bg-black opacity-50"})])
}]

export { render, staticRenderFns }